<template>
  <div> 
        <button class="button" @click="dialog = true"> {{ $t("home.btnBanner1") }} </button>
        <v-dialog v-model="dialog"> 
          <v-card style=";overflow: hidden; padding-left:2%; padding-right: 2%">
            <v-card-actions style="padding-top: 0%; padding-bottom:0%;">
              <v-btn color="primary" block @click="dialog = false" >
                <img src="@/assets/banners/close.png" style="height:18px; position:fixed; z-index:2; left:96%;"></v-btn>
              </v-card-actions>
              <div style="height: 100%">
                <div class="imagenPopUp__container" >
                  <img v-if="this.$i18n.locale == 'es'" src="@/assets/banners/es/Banner_Pop_Web_Kilos_1238x476_1_Esp.jpg" class="imagenPopUp" >
                  <img v-else src="@/assets/banners/en/Banner_Pop_Web_Kilos_1238x476_1_Ing.jpg" class="imagenPopUp" >
            
                </div>
                <BotonesPop/>
              </div>
          </v-card>
        </v-dialog>
      </div>

  
</template>
<script>
import Botonesbanner from '@/components/Botonesbanner.vue'
import BotonesPop from '@/components/BotonesPop.vue'
export default {
  name: 'PopPruebas',
  components: {
    Botonesbanner, BotonesPop
  }, 
  data () {
      return {
        dialog: false,
      }
    },
}
</script>


<style scoped >
.button {
  width: 11.7rem;
  height: 2.7rem;
  font-size: 16px;
}


@media screen and (min-width: 600px) {
  .button {
    width: 16.7rem;
    height: 4.7rem;
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .button {
    width: 31.7rem;
    height: 6.7rem;
    font-size: 3.2rem;
  }
}
</style>