import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contacto" */ '../views/Contacto.vue')
  },
  {
    path: '/Historia',
    name: 'Historia',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Historia" */ '../views/Historia.vue')
  },
  {
    path: '/Parrilladas',
    name: 'Parrilladas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Parrilladas" */ '../views/Parrilladas.vue')
    ,
   
  
  },
  {
    path: '/Sucursales',
    name: 'Sucursales',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Sucursales" */ '../views/Sucursales.vue')
  },
  {
    path: '/Formulario',
    name: 'Formulario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Formulario" */ '../views/Formulario.vue')
  },
  // {
  //   path: '/FormularioEnterate',
  //   name: 'FormularioEnterate',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "FormularioEnterate" */ '../views/FormularioEnterate.vue')
  // },
  {
    path: '/prueba',
    name: 'prueba',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Pruebas2" */ '../components/Calendar.vue')
  },
  {
    path: '/correo',
    name: 'ParrilladasPrueba',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ParrilladasPrueba" */ '../views/ParrilladasPrueba.vue')
  },
  {
    path: '/Menu',
    name: 'Menu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Pruebas2" */ '../views/Menu.vue')
  },


]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior: () => {
  //   window.scrollTo(0, 0);
  // }
})

export default router
