<template>
  <div v-if="showPopup">
    <div class="overlay" @click="closePopup"></div>
    <div class="popup" style="width: 97%;" @click.stop>
      <!-- Contenido del pop-up -->
      <div class="popup-content">
        <!-- Botón de cierre -->
        <button @click="closePopup" class="btn_cerrar_popUp">X</button>
        <div class="imagenPopUp__container">  
          <p class="title">Conoce nuestro Aviso de Privacidad.</p>
          <p>Estamos comprometidos contigo y la seguridad de tu información.</p>

          <!-- <img src="@/assets/banners/es/fto_PopUp_PradoNorte.jpg" class="imagenPopUp"> -->
          <!-- <video autoplay loop muted playsinline preload="auto" class="video" style="object-fit: cover;">
            <source src="@/assets/bannerVideo/Farolito-Navidad-Final-HD.mp4" type="video/mp4">
          </video> -->
        </div>
        <button @click="goToPrivacyPolicy" class="action-button">DA CLICK AQUÍ</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: true, 
    };
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    goToPrivacyPolicy() {
      // URL del aviso de privacidad
      window.location.href = "https://elfarolito.com.mx/Avisos%20de%20privacidad%20fto.pdf";
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  width: 90%; 
  background-color: white;
  padding: 30px 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-align: center;
}

.imagenPopUp__container p,
.imagenPopUp__container .action-button {
  display: block; 
  width: 100%; 
  
}

.imagenPopUp__container p.title{
  font-weight: bold;
}

.popup-content {
  position: relative;
}


.imagenPopUp__container {
  text-align: center;
  display: flex;
  flex-direction: column;
}


.btn_cerrar_popUp {
  position: absolute;
  top: -18px; 
  right: -150px; 
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #080808;


}

.btn_cerrar_popUp:hover{
  font-weight: bolder;
}



.action-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
}

.action-button:hover {
  background-color: #b71c1c;
}

@media screen and (min-width: 320px) {
    .video{
        width:100%; height:100%;
    }
  }
  @media screen and (min-width: 940px) {
    .video{
        width:80%; height:100%;    margin-bottom: -7vw;
    }
  }

  @media screen and (min-width: 1433px) {
    .video{
        width:75%; height:100%;    margin-bottom: -10vw;
    }
  }

  @media screen and (min-width: 2000px) {
    .video{
        width:75%; height:100%;    margin-bottom: -9vw;
    }
  }
</style>
