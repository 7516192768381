<template>

<nav class="navbar sticky-top ">
  <router-link class="navbar-brand white" to="/">
    <img src="@/assets/navbar/farolito_logo.svg" alt="Farolito Logo"
      class="d-inline-block align-center mx-3 logo">
  </router-link>
  <div>
    <div>
      <!-- <a class="navbar-brand align-text-center white enlaceHeaderDesk" href="https://elfarolito.com.mx/menu" @click="bluekai_infinity('Boton_Menu','Menú')">
        {{ $t("navbar.MENU") }}
      </a> href="https://www.menugrg.com.mx:7443/elfarolito/?unidad=236" -->
      <router-link class="navbar-brand white align-text-center enlaceHeaderDesk" to="/Menu">
        {{ $t("navbar.MENU") }}
      </router-link> 
      <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
      <router-link class="navbar-brand white align-text-center enlaceHeaderDesk" to="/Sucursales">
        {{ $t("navbar.SUCURSALES") }}
      </router-link>
      <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
      <router-link class="navbar-brand align-text-center white enlaceHeaderDesk" to="/Parrilladas">
        {{ $t("navbar.PARRILLADAS") }}
      </router-link>
      <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
      <router-link class="navbar-brand align-text-center white enlaceHeaderDesk" to="/Contacto">
        {{ $t("navbar.CONTACTO") }}
      </router-link>
      <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
      <a class="navbar-brand align-text-center white enlaceHeaderDesk"
        href="http://facturaelfarolito.com.mx:9443/portal/Farolito/" @click="infinityFacturacion('FTO_Boton_Menu','Boton Header Facturación')">
        {{ $t("navbar.FACTURACION") }}
      </a>
    </div>
    <button class="btn mx-2 white " @click="changeLanguage">
      <!-- // Codicional para determinar el idioma -->
      <template v-if="this.locale == 'en' ">
        <img src="@/assets/navbar/esp.png" alt="Es" class="d-inline-block align-text-center mx-1 " >
        <span style="font-size: 20px; font-family: 'Bebas Neue Bold' ">ESP</span>
      </template>
      <template v-else>
        <img src="@/assets/navbar/eng.png" alt="En" class="d-inline-block align-text-center mx-1 " >
        <span style="font-size: 20px; font-family: 'Bebas Neue Bold' ">ENG</span>
      </template>
    </button>
  </div>
    <div class="menuMovil">
      <div id="menuMovil__icon" @click="menu">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <!-- <img src="../assets/navbar/hamburger.svg"/> -->
    </div>
    <div id="menuMovil__items" class="menuMovil__items">
      <div class="menuMovil__items__lang">
        <button class="btn mx-2 white " @click="changeLanguage">
          <template v-if="this.locale == 'en' ">
            <img src="@/assets/navbar/esp.png" alt="Es" class="d-inline-block align-text-center mx-1 ">
            <span style="font-size: 20px; font-family: 'Bebas Neue Bold' ">ESP</span>
          </template>
          <template v-else>
            <img src="@/assets/navbar/eng.png" alt="En" class="d-inline-block align-text-center mx-1 ">
            <span style="font-size: 20px; font-family: 'Bebas Neue Bold'">ENG</span>
          </template>

          
        </button>
      </div>
      <div class="menuMovil__items__menu">
        <router-link class="navbar-brand white align-text-center" to="/">
          INICIO
        </router-link>
        <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
        <a class="navbar-brand align-text-center white "
        href="https://elfarolito.com.mx/menu">
          {{ $t("navbar.MENU") }}
        </a>
        <!--<a class="navbar-brand align-text-center white "
          href="https://www.menugrg.com.mx:7443/elfarolito/?unidad=236">
          {{ $t("navbar.MENU") }}
        </a>-->
        <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
        <router-link class="navbar-brand white align-text-center" to="/Sucursales">
          {{ $t("navbar.SUCURSALES") }}
        </router-link>
        <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
        <router-link class="navbar-brand align-text-center white" to="/Parrilladas">
          {{ $t("navbar.PARRILLADAS") }}
        </router-link>
        <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
        <router-link class="navbar-brand align-text-center white" to="/Contacto">
          {{ $t("navbar.CONTACTO") }}
        </router-link>
        <img src="@/assets/navbar/bullet_on.png" width="6px" height="6px" class="align-center">
        <a class="navbar-brand align-text-center white"
          href="http://facturaelfarolito.com.mx:9443/portal/Farolito/">
          {{ $t("navbar.FACTURACION") }}
        </a>
      </div>
      <div class="menuMovil__items__rrss">

        <a href="" target="_blank"><img src="" height="30px" ></a>
        
        <a href="https://twitter.com/farolitooficial/" target="_blank">
        <img src="@/assets/footer/Twitter.svg" height="30px" ></a>
        <a href="https://www.instagram.com/tacoselfarolito_mx/" target="_blank">
        <img src="@/assets/footer/Instagram.svg" height="30px" ></a>
        <a href="https://www.facebook.com/tacoselfarolito/" target="_blank">
        <img src="@/assets/footer/Facebook.svg" height="30px"></a>
        <a href="https://api.whatsapp.com/send?phone=525551053532" target="_blank">
        <img src="@/assets/footer/Whatsapp.svg" height="30px"></a>
      </div>
    </div>
</nav>
</template>

<script>
import { useI18n } from 'vue-i18n';

  export default {
    setup () {
      const { t } = useI18n;
      return { t };
    },
    data: () => ({
      flag : false,
      locale: localStorage.getItem("lang") || "es",
    }),
    name: 'Navbar',
    methods:{
    menu () {
      let menuMovil__icon = document.getElementById("menuMovil__icon");
      let menuMovil__items = document.getElementById("menuMovil__items");
      menuMovil__icon.classList.toggle("active");
      menuMovil__items.classList.toggle("active");
    },
    bluekai(key, value) {
      bk_addPageCtx(key, value);
      bk_doJSTag(93939, 1);
      let testMutation1 = function (msg) {
                  //key               value
        msg.payload["FTO_Boton_Menu"] = "Boton Header Menu";
          msg.payload["email_address"] = "daneroeda@gmail.com";
          msg.payload["button_id"] = "FTO_Boton_Menu";
          msg.payload["source"] = "FarolitoWeb";
          // ------- Cambiar de acuerdo al tipo de página --------
          msg.payload["subtype"] = "Button Clicked";
          msg.payload["target"] = "Button";
          msg.payload["type"] = "Clicked";
          

      };
      ORA.analytics?.addMutation("myuniqueid", testMutation1);
      ORA.click()
      },
      infinityFacturacion(key, value) {
      let testMutation1 = function (msg) {
                  //key               value
        msg.payload[key] = value;
        
        msg.payload["subtype"] = "Page Viewed";
        msg.payload["target"] = "Page";
        msg.payload["type"] = "Viewed";
        
      };
      ORA.analytics?.addMutation("myuniqueid", testMutation1);
      ORA.click()
      },
      changeLanguage() {
        this.locale == 'es' ? localStorage.setItem("lang", "en"): localStorage.setItem("lang", "es");
        this.locale = localStorage.getItem("lang");
        this.$i18n.locale = this.locale;
      },
      engActive () {
        this.locale  == "en" ? this.flag = true: this.flag =  false;
        return this.flag;
      }
    },
    mounted() {
      var URLactual = window.location.pathname;
      URLactual = URLactual.substring(1);
      // console.log(URLactual);
      var arrayTextLink = document.querySelectorAll(".enlaceHeaderDesk");
      switch ( URLactual) {
          case "Sucursales" :
          arrayTextLink[1].classList.add("active")
          break;
          case "Parrilladas":
          arrayTextLink[2].classList.add("active")
            break;
          case "Contacto"  :
          arrayTextLink[3].classList.add("active")
            break;
        default:
            break;
        }

  },
  created () {
    if (!localStorage.getItem("lang") || localStorage.getItem("lang") == null ){
      localStorage.setItem("lang", "es");
    }
  }
}

</script>
