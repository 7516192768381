<template>
  <footer class="footer text-white">
    <!-- <p class=" text-sm-center"> &nbsp; </p>       -->
    <div class="wrapper">
      <div class="izq" style="padding-top:3.5rem ;">
            <!-- <a href="https://drive.google.com/file/d/1j-UALduQqNyJOIHybiny40iU7kyj-hZd/view?usp=share_link" target="_blank" class="text-white">
            TÉRMINOS Y CONDICIONES
          </a> -->
          <a href="https://www.gruporestauranterogigante.com.mx/vacantes" target="_blank" class="text-white ">
            TRABAJA CON NOSOTROS
          </a>
            <a href="./FTO_TyC_Web.pdf" target="_blank" class="text-white ">
              TÉRMINOS Y CONDICIONES
            </a>
          <!-- <a href="https://drive.google.com/file/d/1j9OdXocWpZkLdtOfyIw9vvjgA75LzrQI/view?usp=share_link" target="_blank" class="text-white">PRIVACIDAD DE DATOS</a> -->
          <div>
            <div v-if="this.$i18n.locale == 'es'" >
              <a href="./Avisos de privacidad fto.pdf" target="_blank" class="text-white privacidad" style="padding-top: 1.5rem ;">PRIVACIDAD DE DATOS</a>
            </div>
            <div v-else >
              <a href="./FTO_AVISO_PRIVACIDAD_020223_ENG.pdf" target="_blank" class="text-white privacidad" style="padding-top: 1.5rem ;">PRIVACIDAD DE DATOs</a>
            </div>
          </div>
          
          
          
            
          <!-- <pdf/> -->
      </div>
      <div class="center" >
        <center v-html="$t('footer.conocemasgrg')" >
        </center>
        <img src="@/assets/footer/logoGRG.svg" height="70px">
        <center class="copyr">COPYRIGHT ALL RIGHTS RESERVED 2022®</center>
      </div>
      <div class="der-icons">
        <button type="button">
          <a href="https://twitter.com/farolitooficial/" target="_blank">
            <img src="@/assets/footer/x.svg" height="">
          </a>
        </button>
        <button type="button">
          <a href="https://www.instagram.com/tacoselfarolito_mx/" target="_blank">
            <img src="@/assets/footer/Instagram.svg" height="">
          </a>
        </button>
        <button type="button">
          <a href="https://www.facebook.com/tacoselfarolito/" target="_blank">
            <img src="@/assets/footer/Facebook.svg" height="">
          </a>
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
// import pdf from './pdf.vue';
export default {
  name: 'Footer',
  methods:{
    obtenerArchivosServidor(ruta) {
      if (ruta != "") {
        return this.$baseURL + ruta;
      }
    },
  },
}
</script>
  
<style >

</style>