
<template> <!-- POPUP SUSCRIPCION EXITOSA FORMULARIO-->
    <div style="margin: auto;">
        <button  class="button" @click="dialog = true"> suscribirme </button>
        <v-dialog v-model="dialog">  
            <center>
                <v-card class="graciascontac" >
                    <div>
                        <center>
                        <h4 class="h4g">¡GRACIAS POR CONTACTARNOS!</h4>
                        <p class="pg">A la brevedad nos comunicaremos contigo.</p>
                        <button class="button buttong" @click="dialog = false"  > ok</button>
                        </center>
                    </div>
                </v-card>
            </center>
        </v-dialog>
    </div>



<v-select
  label="Select"
  :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
></v-select>

</template>

<script>
import Botonesbanner from '@/components/Botonesbanner.vue'
export default {
    name: 'GContactarnos',
    data(){
        return{
            dialog: false,
        }
    },

}
</script>