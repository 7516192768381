    <!-- --------- BOTONES DE BANNER --------- -->
<template> 
  <div class="botonesContactoDesk">
    <!-- <button type="button">
      <a href="https://api.whatsapp.com/send?phone=525551053532" target="_blank" @click="bluekai('Boton_PedidoDomicilio','WhatsApp')">
        <img v-if="this.$i18n.locale == 'es'" src="@/assets/banner/boton_esp/whatsapp_esp.png" alt="slogan" >
        <img v-else src="@/assets/banner/boton_eng/whatsapp_eng.png" alt="slogan" >
      </a>
    </button> -->
  
    <button type="button">
      <a href="tel:+8008226011" target="_blank" @click="bluekai('Boton_PedidoDomicilio','LineaTaco')">
        <img v-if="this.$i18n.locale == 'es'" src="@/assets/banner/boton_esp/taco_esp.png" alt="slogan" >
        <img v-else src="@/assets/banner/boton_eng/taco_eng.png" alt="slogan" >
      </a>
    </button>
  
    <button type="button">
      <a href="https://www.rappi.com.mx/" target="_blank" @click="bluekai('Boton_PedidoDomicilio','Rappi')">
        <img v-if="this.$i18n.locale == 'es'" src="@/assets/banner/boton_esp/rappi_esp.png" alt="slogan" >
        <img v-else src="@/assets/banner/boton_eng/rappi_eng.png" alt="slogan" >
      </a>
    </button>

    <button type="button">
      <a href="https://www.ubereats.com/mx/brand/el-farolito?&utm_campaign=el-farolito-mx&utm_source=instagram&utm_medium=brandpage" target="_blank" >
        <img v-if="this.$i18n.locale == 'es'" src="@/assets/banner/boton_esp/ubereats_esp.png" alt="slogan" >
        <img v-else src="@/assets/banner/boton_eng/ubereats_eng.png" alt="slogan" >
      </a>
    </button>
  
    <button type="button">
        <img v-if="this.$i18n.locale == 'es'" src="@/assets/banner/boton_esp/didi_esp.png" alt="slogan" >
        <img v-else src="@/assets/banner/boton_eng/didi_eng.png" alt="slogan" >
    </button>


  </div>
</template>
<script>
export default {
  name: 'Botonesbanner',
  methods: {
    bluekai(key, value) {
      bk_addPageCtx(key, value);
      bk_doJSTag(93939, 1);
    }
  }
}
</script>
  
<style >

</style>