<template>
    <v-slide-group show-arrows center-active >
      <v-slide-group-item v-for="n in 4" :key="n" v-slot="{active, toggle}">
        <div :color="active ? 'grey' : 'grey'" class="" v-if="n == 1"  @click="toggle">
          <img src="@/assets/home/NuestraReceta.png" alt="" class="imageFavoritos">
          <div class="card">
            <h4>{{ $t("home.seccionAutenticos.card1.titulo") }}</h4>
            <p>{{ $t("home.seccionAutenticos.card1.descripcion") }}</p>
          </div>
        </div>
        <div :color="active ? 'grey' : 'grey'" class="" v-if="n == 2"  @click="toggle">
          <img src="@/assets/home/Colorines.png" alt="" class="imageFavoritos">
          <div class="card">
            <h4>{{ $t("home.seccionAutenticos.card2.titulo") }}<span>®</span></h4>
            <p>{{ $t("home.seccionAutenticos.card2.descripcion") }}</p>
          </div>
        </div>
        <div :color="active ? 'grey' : 'grey'" class="" v-if="n == 3"  @click="toggle">
          <img src="@/assets/home/Servicio.png" alt="" class="imageFavoritos">
          <div class="card">
            <h4>{{ $t("home.seccionAutenticos.card3.titulo") }}</h4>
            <p>{{ $t("home.seccionAutenticos.card3.descripcion") }}</p>
          </div>
        </div>
        <div :color="active ? 'grey' : 'grey'" class="" v-if="n == 4"  @click="toggle">
          <img src="@/assets/home/Instalaciones.png" alt="" class="imageFavoritos">
          <div class="card">
            <h4>{{ $t("home.seccionAutenticos.card4.titulo") }}</h4>
            <p style="text-align: center;">{{ $t("home.seccionAutenticos.card4.descripcion") }}</p>
          </div>
        </div>
      </v-slide-group-item>
    </v-slide-group>

  <!-- <v-sheet
      class="mx-auto"
      elevation="8"
      max-width="800"
    >
      <v-slide-group
        v-model="model"
        class="pa-4"
        selected-class="bg-primary"
        show-arrows
        
      >
        <v-slide-group-item
        style="display: flex;"
          v-for="n in 4"
          :key="n"
          v-slot="{ isSelected, toggle, selectedClass }"
        >
          <v-card
            color="grey-lighten-1"
            :class="['ma-4', selectedClass]"
            height="200"
            width="100"
            @click="toggle"
          >
            <div class="d-flex fill-height align-center justify-center">
              <v-scale-transition>
                <v-icon
                  v-if="isSelected"
                  color="white"
                  size="48"
                  icon="mdi-close-circle-outline"
                ></v-icon>
              </v-scale-transition>
            </div>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
    </v-sheet> -->
</template>
<script>
import { useI18n } from 'vue-i18n';

export default { 
  setup () {
    const { t } = useI18n;
    return { t };
  },
}
</script>