<template>
    <!-- FORMULARIO ENTERATE DE LO NUEVO -->
  <div class="v-container formularioSuscribete"><!-- display: none -->
    <div style="" >
      <h2>
        <center v-html="$t('contacto.formEnterate.titulo')"></center>
      </h2>
      <p>
        <!-- <center>Déjanos tus datos y sigue de cerca todas las novedades que tenemos para ti </center> -->
        <center>{{ $t("contacto.formEnterate.descripcion")}}</center>
      </p>
    </div>
  <!-- <div class="pd__formulario"> -->
    <div class="">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <div class="contenedorsuscribete">
          
          <v-row style="justify-content: center; padding: 0px; align-items: flex-end">
            <v-col cols="12" md="6" lg="2">
              <div style="">
                <!-- <p><strong> *Nombre Completo </strong></p> -->
                <v-text-field v-model="name" id="nameSuscribete" class="inputFarolito" rounded  variant="outlined" :counter="30" 
                :rules="nameRules" :placeholder=" $t('contacto.formEnterate.nombre') " required >
                </v-text-field> 
                <!-- esto es para idioma :placeholder=" $t('home.historia.titulo')" -->
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <div style="">
                <!-- <p><strong> *Apellido Paterno </strong></p> -->
                <v-text-field v-model="appaterno" class="inputFarolito"  variant="outlined"  :counter="30"
                  :rules="nameRules" :placeholder=" $t('contacto.formEnterate.apPaterno') " required id="appaternoSuscribete">
                </v-text-field> 
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <div style="">
                <!-- <p><strong> Apellido Materno </strong></p> -->
                <v-text-field v-model="apmaterno" class="inputFarolito"  variant="outlined"  :placeholder=" $t('contacto.formEnterate.apMaterno') " :counter="30" id="apmaternoSucribete"></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <div style="">
                <!-- <p><strong> *Correo electrónico </strong></p> -->
                <v-text-field v-model="email" class="inputFarolito" variant="outlined" :rules="emailRules" :placeholder=" $t('contacto.formEnterate.correo') " required id="emailSuscribete"></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <div style="padding:0px">
                <center>
                  <button class="buttonmovil btnSuscribete" style="" @click.prevent="enviarSuscribete" id="submitSuscribete">
                    {{ $t("contacto.formEnterate.btnForm1") }}
                  </button> 
                </center>
              </div>
              <br>
            </v-col>
            <v-col cols="12" md="12" style="padding-top: 0px">
              <v-checkbox v-model="checkbox" :rules="[v => !!v || '*Debes aceptar para continuar.']"
                :label="$t('contacto.formEnterate.check')" 
                required class="checkboxstyle"></v-checkbox>
            </v-col>
          </v-row>
        </div>
        </v-container>
      </v-form>
    </div>
  </div>


  <!-- suscNoExitosa -->
<div class="popupformularios" id="noExitoso">
  <div style=" bottom:0px; background-color:transparent; margin: auto" >
    <div class="noexitosa" style="background-color: white; margin: auto auto" >
      <div>
        <center>
          <img src="@/assets/forms/cancel.png" class="imgne"/>
            <h4 class="h4ne">{{ $t('contacto.suscNoExitosa')}}</h4>
            <p class="noe"> {{ $t('parrilladas.popNoExitoso.contenido')}}</p>
            <button class="button buttonne" @click="cerrarPopUp('noExitoso')" > {{ $t('parrilladas.btnpop')}}</button>
        </center>
      </div>
    </div>
  </div>
</div>

<!--suscExitosa -->
<div class="popupformularios" id="exitoso">
  <div style=" bottom:0px; background-color:transparent; margin: auto" >
    <div class="exitosa" style="background-color: white; margin: auto auto" >
      <div>
        <center>
          <img src="@/assets/forms/check.png" class="imge"/>
          <h4 class="h4e">{{$t('contacto.suscExitosa')}}</h4>
          <p class="mensajeGracias">{{$t('contacto.suscExitosa.suscExitosaCont')}}</p>
          <button class="button buttone" @click="cerrarPopUp('exitoso')">ok</button>
        </center>
      </div>
    </div>
  </div>
</div>


  <!-- TERMINA FORMULARIO ENTERATE DE LO NUEVO -->
</template>

<script>
 import Navbar from '@/components/Navbar.vue'
 import Footer from '@/components/Footer.vue'
 import Datepicker from '@vuepic/vue-datepicker'; //datepicker
 import '@vuepic/vue-datepicker/dist/main.css' //datepicker
 import axios from 'axios'
 import Botonesbanner from '@/components/Botonesbanner.vue';
 import BotonesContactoMovil from '@/components/BotonesContactoMovil.vue';
 import GContactarnos from '@/formsPop/GContactarnos.vue';
 import suscExitosa from '@/formsPop/suscExitosa.vue';
 import suscNoExitosa from '@/formsPop/suscNoExitosa.vue';

export default {
  name: 'FormularioEnterate',
  components: {
    Navbar, Footer,Botonesbanner, BotonesContactoMovil,Datepicker, suscNoExitosa
  },
  data() {
    return {
      date: null,
      flag: false,
      dialog:true,
    };
  },
  data:() => ({
        snackbar: false,
        textT: 'HOLA datos enviados',
        timeout:2000,

    valid: true,
      name: '', apmaterno: '', appaterno:'', 
      nameRules: [
        v => !!v || '*Dato requerido',
        v => (v && v.length <= 30) || 'No debe exceder de los caracteres',
      ],
      email: '',
      emailRules: [
        v => !!v || '*E-mail requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail debe ser válido ',
      ],
      number:'',
      numberRules:[
        v => !!v || ' ',
        v =>  ((v>0 || v<9))  || 'Debe ser un número',
        v => (v.length == 10 ) || ' Deben ser de 10 digitos',
      ],
      select: null,
      checkbox : false,
      dataForm: null,
    }),
    
    methods: {
      async validate () {
        const { valid } = await this.$refs.form.validate()
        if (valid) alert('Form is valid')
      },
      reset () { 
        this.$refs.form.reset() 
      },
      resetValidation () { 
        this.$refs.form.resetValidation()
      },
      enviarSuscribete() {
        this.snackbar = true;
        this.dataForm = {
          FirstName: this.name,
          LastName: this.appaterno,
          SecondLastName: this.apmaterno,
          DateOfBirth: "1998-02-10",
          EmailAddress: this.email,
          MobileNumber: this.number,
          Farolito_Normal: 1,
          Farolito_Parrillada: 0,
        }
        this.$refs.form.validate().then(
          (resp) => {
            // btnSubmit = document.getElementById("submitSuscribete");
            this.formIsValid = resp.valid;
            if (this.formIsValid) {
              document.getElementById("submitSuscribete").setAttribute("disabled",  true);
              document.getElementById("submitSuscribete").style.backgroundColor = "#555659";
              // Se quita el color rojo de los inputs que quedaron marcados
              document.getElementById("nameSuscribete").classList.remove("borderAlert");
              document.getElementById("appaternoSuscribete").classList.remove("borderAlert");
              document.getElementById("emailSuscribete").classList.remove("borderAlert");
              this.bluekai_infinity();
              axios.post('https://elfarolito.com.mx/formularios/formularioSuscribete.php',
                this.dataForm
                ).then(response => {
                  document.getElementById("exitoso").classList.add("active");
                  // Se resetea todos los campos del formulario
                  this.$refs.form.reset() 
                  this.name = "";
                  this.appaterno = "";
                  this.email = "";
                  this.checkbox = false;
                  // Se resetean las validaciones del formulario
                  setTimeout(() => {
                    this.$refs.form.resetValidation()
                    document.getElementById("submitSuscribete").removeAttribute("disabled");
                    document.getElementById("submitSuscribete").style.backgroundColor = "#F7BA27";
                  }, 10);
                  // console.log(response.data.STATUS);
                  console.log(response.data.Mensale);
                  if(response.data.STATUS == "Error"){
                    axios.post('https://elfarolito.com.mx/formularios/formularioSuscribete.php',
                      this.dataForm); 
                  }
                  
                }).catch(error => {
                  console.log(error)
              });
            }
            else {
              // <suscNoExitosa/>
              document.getElementById("noExitoso").classList.add("active");
              this.name == ""
                ? document.getElementById("nameSuscribete").classList.add("borderAlert") 
                : document.getElementById("nameSuscribete").classList.remove("borderAlert");
              this.appaterno == ""
                ? document.getElementById("appaternoSuscribete").classList.add("borderAlert") 
                : document.getElementById("appaternoSuscribete").classList.remove("borderAlert");
              this.email == ""
                ? document.getElementById("emailSuscribete").classList.add("borderAlert") 
                : document.getElementById("emailSuscribete").classList.remove("borderAlert");
            }
          }
        );
      },
      cerrarPopUp (id) {
        document.getElementById(id).classList.remove('active')
      },
      bluekai_infinity(){
      var URLactual = window.location.pathname;
      if (URLactual == "/" || URLactual == "/Home") {
        bk_addPageCtx("FTO_Suscribete", "Suscribete");
        bk_doJSTag(93939, 1);
        let testMutation1 = function (msg) {
          //key               value
          msg.payload["FTO_Boton_Suscribete"] = "https://elfarolito.com.mx/";
          msg.payload["email_address"] = "daneroeda@gmail.com";
          msg.payload["button_id"] = "FTO_Boton_Suscribete";
          msg.payload["source"] = "FarolitoWeb";
          msg.payload["subtype"] = "Form Summited";
          msg.payload["target"] = "Form";
          msg.payload["type"] = "Summited";
          msg.payload["data.wt.es"] = "/Home";

        };
        ORA.analytics?.addMutation("myuniqueid", testMutation1);
        ORA.click()
      }
      else if (URLactual == "/Contacto") {
        bk_addPageCtx("FTO_Suscribete", "Suscribete2");
        bk_doJSTag(93939, 1);
        let testMutation1 = function (msg) {
          //key               value
          msg.payload["FTO_Boton_Suscribete2"] = "https://elfarolito.com.mx/contacto/";
          msg.payload["email_address"] = "daneroeda@gmail.com";
          msg.payload["button_id"] = "FTO_Boton_Suscribete";
          msg.payload["source"] = "FarolitoWeb";
          msg.payload["subtype"] = "Form Summited";
          msg.payload["target"] = "Form";
          msg.payload["type"] = "Summited";
          msg.payload["data.wt.es"] = "/Contacto";
        };
        ORA.analytics?.addMutation("myuniqueid", testMutation1);
        ORA.click()
      }
    }
  },
  created() {

    }
  }
</script>