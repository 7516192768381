<template>
    <div> 
        <button class="button" @click="dialog = true"> {{ $t("home.btnBanner1") }} </button>
        <v-dialog v-model="dialog"> 
          <v-card style="overflow: hidden; padding-left:2%; padding-right: 2%">
            <v-card-actions style="padding-top: 0%; padding-bottom:0%; position: relative">
              <v-btn color="primary" block @click="dialog = false" >
                <img src="@/assets/banners/close.png" style="height:18px; position:fixed; z-index:2; left:96%;  "></v-btn>
              </v-card-actions>
              <div style="height: 100%">
                <div class="imagenPopUp__container" >
                  <img src="@/assets/banners/es/Banner_Pop_Web_Colorines_1238x476_Esp.webp" class="imagenPopUp"  >
                </div>
                <BotonesPop/>
              </div>
          </v-card>
        </v-dialog>
      </div>
</template>
<script>
import Botonesbanner from '@/components/Botonesbanner.vue'
import BotonesPop from '@/components/BotonesPop.vue'
export default {
  name: 'PopPruebas',
  components: {
    Botonesbanner, BotonesPop
  }, 
  data () {
      return {
        dialog: false,
      }
    },
}
</script>